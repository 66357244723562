// Import styles
import "./main.scss";

import $ from "jquery";

import "./scripts/icons";
import loadResponsiveImages from "./scripts/responsiveImage";

$(document).ready(function () {
  initializePage();
});

function initializePage() {
  addNavbarHandlers();

  var responsiveImages = document.getElementsByClassName("responsiveImage");
  loadResponsiveImages(responsiveImages);
}

function addNavbarHandlers() {
  $(".navbar-burger").click(toggleMenuState);
  $(".navbar-item").click(closeMenu);
}

function toggleMenuState() {
  $(".navbar-burger").toggleClass("is-active");
  $(".navbar-menu").toggleClass("is-active");
}

function closeMenu() {
  $(".navbar-burger").removeClass("is-active");
  $(".navbar-menu").removeClass("is-active");
}
