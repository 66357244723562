export function calculateAspectRatio(width, height) {
  return height / width;
}

export function calculateAspectRatioPercentage(width, height) {
  var aspectRatio = calculateAspectRatio(width, height);

  var aspectRatioPercentage = (aspectRatio * 100) + "%";
  return aspectRatioPercentage;
}

export default calculateAspectRatioPercentage;
