import { library, dom } from '@fortawesome/fontawesome-svg-core'

// Add all icons being used in project here...
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
library.add(faLinkedin);
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
library.add(faMapMarkerAlt);
import { faUser } from '@fortawesome/free-solid-svg-icons'
library.add(faUser);
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
library.add(faEnvelope);

// Ask Font Awesome to manage all FA icons on the page (does some icon -> SVG conversion etc.)
dom.watch();
