import aspectRatioPercentage from "./aspectRatio";

var images = require.context('../../assets/images', true, /\.(gif|png|jpe?g|svg)$/i);

function isLightboxSupportRequired(element) {
  return element.dataset.toggle === "lightbox";
}

function addLightboxAttributes(element, imageSrc) {
  element.dataset["remote"] = imageSrc;
}

function addAltTextToElement(element, altText) {
  if (typeof altText !== "undefined") {
    element.alt = altText;
  }
}

function createPlaceholderImageElement(placeholderImageSrc, dataset) {
  var placeholderImage = new Image();

  placeholderImage.classList.add('img-small');
  placeholderImage.src = placeholderImageSrc;

  addAltTextToElement(placeholderImage, dataset.alt);

  placeholderImage.onload = function () {
    placeholderImage.classList.add('loaded');
  };

  return placeholderImage;
}

function createFinalImageElement(finalImageSrc, finalImageSrcSet, dataset) {
  var fullImage = new Image();

  fullImage.src = finalImageSrc;
  fullImage.srcset = finalImageSrcSet;

  addAltTextToElement(fullImage, dataset.alt);

  fullImage.onload = function () {
    fullImage.classList.add('loaded');
  };

  return fullImage;
}

function loadResponsiveImage(imageElement) {
  var imageSource = images(imageElement.dataset.image);
  var firstChild = imageElement.firstChild; // We insert all responsive image elements before existing child content to maintain ordering

  var placeholderImage = createPlaceholderImageElement(imageSource.placeholder, imageElement.dataset);
  imageElement.insertBefore(placeholderImage, firstChild);

  var fullImage = createFinalImageElement(imageSource.src, imageSource.srcSet, imageElement.dataset);
  imageElement.insertBefore(fullImage, firstChild);

  var paddingDiv = document.createElement('div');
  paddingDiv.style.paddingBottom = aspectRatioPercentage(imageSource.width, imageSource.height);
  imageElement.insertBefore(paddingDiv, firstChild);

  if (isLightboxSupportRequired(imageElement)) {
    var largestSrcSetImage = getLargestImageFromSrcSet(imageSource.srcSet);
    addLightboxAttributes(imageElement, largestSrcSetImage);
  }
}

function getLargestImageFromSrcSet(srcSet) {
  var lastItem = srcSet.split(",").pop();
  var imageComponent = lastItem.split(" ")[0];

  return imageComponent;
}

function loadResponsiveImages(responsiveImages) {
  var count;
  for (count = 0; count < responsiveImages.length; count++) {
    loadResponsiveImage(responsiveImages[count]);
  }
}

export default loadResponsiveImages;
